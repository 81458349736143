import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const DOCUMENTATION_PATHS = {
  _: 'documentation',
  documentationAPI: 'api',
  documentationDB: 'db',
  documentationPackage: 'package'
};
const DOCUMENTATION_ROUTES = {
  path: DOCUMENTATION_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'external.documentation.routes.title',
    description: 'external.documentation.routes.desc',
    icon: 'zui-icon-general-information',
    cache: true,
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_documentation,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: DOCUMENTATION_PATHS.documentationAPI,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.documentation.routes.api.title',
        description: `external.documentation.routes.api.desc`,
        icon: 'zui-icon-server-server-server-list',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_documentation,
          required: ['api']
        })
      }
    }, {
      path: DOCUMENTATION_PATHS.documentationDB,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.documentation.routes.db.title',
        description: `external.documentation.routes.db.desc`,
        icon: 'zui-icon-server-server-server',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_documentation,
          required: ['db']
        })
      }
    }, {
      path: DOCUMENTATION_PATHS.documentationPackage,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.documentation.routes.package.title',
        description: `external.documentation.routes.package.desc`,
        icon: 'zui-icon-setup-qualification-measurement',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_documentation,
          required: ['package']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { DOCUMENTATION_PATHS, DOCUMENTATION_ROUTES };
