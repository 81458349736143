import { SystemLanguage } from '@zeiss/ng-vis-common/types';
const enGBExternal = {
  documentation: {
    routes: {
      title: 'Documentation',
      desc: 'Documentation overview',
      api: {
        desc: 'Overview of API documentation',
        title: 'API'
      },
      db: {
        desc: 'Overview of database documentation',
        title: 'Database'
      },
      package: {
        desc: 'Overview of package documentation',
        title: 'Package'
      }
    }
  }
};
const esESExternal = {
  documentation: {
    routes: {
      desc: 'Documentación.',
      title: 'Documentación',
      api: {
        desc: 'Visión general de documentación de API',
        title: 'Documentación de API'
      },
      db: {
        desc: 'Visión general de documentación de base de datos',
        title: 'Documentación de base de datos'
      }
    }
  }
};
const deDEExternal = {
  documentation: {
    routes: {
      title: 'Dokumentation',
      desc: 'Überblick über die Dokumentation',
      api: {
        desc: 'Überblick über die API-Dokumentation',
        title: 'API'
      },
      db: {
        desc: 'Überblick über die Datenbank-Dokumentation',
        title: 'Datenbank'
      },
      package: {
        desc: 'Überblick über die Package-Dokumentation',
        title: 'Package'
      }
    }
  }
};

// Translations which can be used in VP outside library. Please do not export everything, this is exception, not a rule!
const DOCUMENTATION_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: enGBExternal,
  [SystemLanguage['es-ES']]: esESExternal,
  [SystemLanguage['de-DE']]: deDEExternal
};

/**
 * Generated bundle index. Do not edit.
 */

export { DOCUMENTATION_TRANSLATIONS };
